import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="form-block"
export default class extends Controller {
  static targets = ['control', 'input']

  connect() {
    this.controlInputs()
  }

  controlInputs() {
    const disabled = !this.controlTarget.checked
    console.log(disabled);

    this.inputTargets.forEach( target => { target.disabled = disabled });
  }
}


import { Controller } from "@hotwired/stimulus";

import consumer from '../channels/consumer'
import CableReady from 'cable_ready'

export default class extends Controller {
  static targets = ["form", "display"]

  static values = {
    url: String,
    domId: String,
  }

  displayTargetConnected(el) {
    const id = el.dataset.previewArticleId;
    console.log(id)

    consumer.subscriptions.create({ channel: 'ArticlePreviewChannel', article_id: id }, {
      connected () {
        console.log("connected as a preview article preview channel")
      },
      received (data) {
        console.log(data);
        if (data.cableReady) CableReady.perform(data.operations)
      }
    })
  }


  launch(event) {
    const button = event.target,
      originalAction = this.formTarget.action,
      originalTarget = this.formTarget.target

    event.preventDefault();

    window.open('about:blank', button.target, 'toolbar=no,location=no,menubar=no,scrollbars=yes,resizable=yes')

    this.formTarget.action = button.href
    this.formTarget.target = button.target
    this.formTarget.submit()
    this.formTarget.action = originalAction
    this.formTarget.target = originalTarget
  }

  transmit(event) {
    let timer = this.timer

    clearTimeout(timer) ;
    this.timer = setTimeout(() => { this.sendData.apply(this); }, 1000);
  }

  sendData() {
    const formData = new FormData(this.formTarget)
    const token = this.formTarget.querySelector("[name='authenticity_token']").value

    fetch(this.urlValue, {
      body: formData,
      method: "PUT",
      headers: {
        "X-CSRF-Token": token,
        "X-Requested-With": "XMLHttpRequest"
      }
    })
  }
}

import { Controller } from "@hotwired/stimulus";



export default class extends Controller {

  static targets = ["link", "value"]

  copy(e) {
    const text = this.getText();

    navigator.clipboard.writeText(text)
    e.preventDefault();

    this.dispatch('copy', { detail: { text: text } })
  }


  getText() {
    if(this.hasLinkTarget) {
      return this.linkTarget.href;
    } else if(this.hasValueTarget) {
      return this.valueTarget.textContent.trim();
    }
  }
}

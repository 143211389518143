import { Controller } from "@hotwired/stimulus";
import EasyMDE from 'easymde';

export default class extends Controller {
  connect () {
    let height = this.element.rows * 5;

    this.editor = new EasyMDE({
      element: this.element,
      autosave: { enabled: false },
      forceSync: true,
      maxHeight: `${height}rem`,
      indentWithTabs: false,
      status: false,
      hideIcons: ["preview"],
    })
  }

  disconnect () {
    if (this.editor.parentNode) {
      this.editor.toTextArea()
      this.editor.cleanup();
    }
  }
}

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  update (text) {
    this.element.placeholder = text;
  }

  humanize (text) {
    const name = text.replace(/^.*\/|\.[^.]*$/g, '').replace(/_/g, ' ')
    this.element.placeholder = name
  }
}

import { Controller } from '@hotwired/stimulus';

import TomSelect from 'tom-select';

export default class extends Controller {
  connect () {
    this.selector = new TomSelect(this.element, {
      create: false,
      closeAfterSelect: true,
      labelField: "login",
      searchField: ["login", "name"],
      selectOnTab: true,
      plugins: {
        remove_button: {},
        clear_button: {},
      },
      render: {
        option: this.renderOption
      }
    })
  }

  disconnect () {
    this.selector.destroy()
  }

  renderOption (data, escape) {
    return `
      <div>
        <img src="${data.avatar}" height="50px" />
        <div class="name">
          <div>${escape(data.name)}</div>
          <span class="text-muted">${escape(data.login)}</span>
        </div>
      </div>`
  }
}

import { Controller } from '@hotwired/stimulus'
import TomSelect from 'tom-select';

export default class extends Controller {
  static targets = [ "static" ]

  connect () {
    this.selector = new TomSelect(this.element, {
      create: false,
      closeAfterSelect: true,
      plugins: {
        remove_button: {},
        clear_button: {},
      }
    })
  }

  disconnect () {
    this.selector.destroy()
  }
}

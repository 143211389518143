import { Controller } from "@hotwired/stimulus"
import debounce from "debounce";

// Connects to data-controller="form"
export default class extends Controller {
  static targets = [ 'conceal' ]

  initialize() {
    this.submit = debounce(this.submit.bind(this), 300)
  }

  concealTargetConnected(el) {
    el.hidden = true;
  }

  concealTargetDisconnected(el) {
    el.hidden = false;
  }

  submit() {
    this.element.requestSubmit();
  }
}

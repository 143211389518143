// Set up Stimulus and Turbo
import "@hotwired/turbo-rails";
import { Application } from '@hotwired/stimulus';
import { registerControllers } from 'stimulus-vite-helpers';

const application = Application.start()

registerControllers(application, import.meta.glob('../controllers/*', { eager: true }));
registerControllers(application, import.meta.glob('../../components/*.js', { eager: true }));

// Import Stylesheets
import "~/stylesheets/bwoa.scss"
import 'bootstrap-icons/font/bootstrap-icons.css'

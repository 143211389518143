import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["toggleable"]
  static classes = ["hidden"]

  connect() {
    this.toggleableTarget.classList.add(this.hiddenClass)
  }

  toggle(ev) {
    this.toggleableTarget.classList.toggle(this.hiddenClass)

    ev.preventDefault();
  }

  hide(ev) {
    if(!this.element.contains(ev.target)) {
      this.toggleableTarget.classList.add(this.hiddenClass)
    }
  }
}

import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    url: String
  }

  connect() {
    window.DiscourseEmbed = {
      discourseUrl: "https://arena.athas.org/",
      discourseEmbedUrl: this.urlValue
    }

    var d = document.createElement('script'); d.type = 'text/javascript'; d.async = true;
    d.src = DiscourseEmbed.discourseUrl + 'javascripts/embed.js';
    (document.getElementsByTagName('head')[0] || document.getElementsByTagName('body')[0]).appendChild(d);
  }
}
